import classnames from 'classnames';
import type { ReactNode } from 'react';
import React, { useEffect, useRef, useState } from 'react';

import Title from '@/components/common/title';

import styles from './styles.module.less';
import { MapDataList } from '../contant';
import intl from 'react-intl-universal';

interface Iprops {
    title?: ReactNode;
    desc?: ReactNode;
}
const PcFacilities: React.FC<Iprops> = (props) => {
    const { title, desc } = props;
    const imgConRef = useRef<HTMLDivElement | null>(null);
    const [scaleX, setScaleX] = useState(0);

    useEffect(() => {
        const updateDimensions = () => {
            const divCur = imgConRef.current;
            if (divCur) {
                const width = divCur.clientWidth;
                setScaleX(1 + (width - 1920) / 1920);
            }
        };

        window.addEventListener('resize', updateDimensions);
        updateDimensions();

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles['map-con']}>
                <Title title={title} desc={desc} />
            </div>
            <div className={styles['map-bg-con']} ref={imgConRef}>
                <img loading="lazy" src="/business/oss-service/finovy-cloud-website/img/home/pc/map.png" alt="" />
                {MapDataList.map((item) => {
                    return (
                        <div
                            className={styles.item}
                            style={{
                                marginLeft: item.marginLeft * scaleX,
                                top: item.top * scaleX,
                            }}
                            key={item.id}
                        >
                            <div className={styles['text-box']}>
                                <div className={styles.title}>{intl.get(item.title)}</div>
                            </div>
                            <div className={classnames(styles['images-box'], styles.act)}>
                                <img
                                    loading="lazy"
                                    src="/business/oss-service/finovy-cloud-website/img/home/location.svg"
                                    style={{ width: item.width }}
                                    alt=""
                                />
                                <div className={styles['flow-mark']}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div className={styles.mask}></div>
            </div>
        </div>
    );
};
export default PcFacilities;
