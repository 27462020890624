import type { ReactNode } from 'react';
import React from 'react';

import TransferCom from '@/components/common/transferCom';

import IpadAdvantage from './ipad';
import MAdvantage from './m';
import PcAdvantage from './pc';

interface Iprops {
    title?: ReactNode;
    desc?: ReactNode;
    mTitle?: ReactNode;
}
const Advantage: React.FC<Iprops> = (props) => {
    return (
        <TransferCom
            m={<MAdvantage {...props} />}
            ipad={<IpadAdvantage {...props} />}
            pc={<PcAdvantage {...props} />}
        />
    );
};
export default Advantage;
