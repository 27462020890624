import { Button } from 'antd';
import type { ReactNode } from 'react';
import React from 'react';
import intl from 'react-intl-universal';

import IconFont from '@/components/common/IconFont';
import Title from '@/components/common/title';

import styles from './styles.module.less';
import { homeCloudList } from '../contant';
import classNames from 'classnames';
import { useRouter } from 'next/router';
interface Iprops {
    title?: ReactNode;
    desc?: ReactNode;
}
const PcOneStop: React.FC<Iprops> = (props) => {
    const { title, desc } = props;
    const isEnLocale = intl.getInitOptions().currentLocale === 'en-US';
    const router = useRouter();
    return (
        <div className={styles.container}>
            <Title title={title} desc={desc} />
            <div className={styles.list}>
                {homeCloudList.map((item) => {
                    return (
                        <a
                            key={item.id}
                            className={classNames(styles.item, isEnLocale && styles.en_item)}
                            href={item.url}
                        >
                            <div>
                                <div className={styles['title-box']}>
                                    <p>{intl.get(item.title)}</p>
                                    <IconFont type="icon-jiantou" className={styles['icon-arrow']}></IconFont>
                                </div>
                                <p className={styles.desc}>{intl.get(item.desc)}</p>
                                <div className={styles['tag-list']}>
                                    {item.tagList.map((tagItem) => {
                                        return (
                                            <span className={styles['tag-item']} key={tagItem}>
                                                {intl.get(tagItem)}
                                            </span>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className={styles.price}>
                                <span>{item.price}</span>
                                <span>{intl.get('Unit.USD').d('USD')}</span>
                                <span>{intl.get('Home.UnitNew2').d('/起')}</span>
                            </div>
                        </a>
                    );
                })}
            </div>
            <div className={styles['more-btn-box']}>
                <Button
                    className={styles['more-btn']}
                    type="primary"
                    size="large"
                    onClick={() => {
                        router.push('/contact/');
                    }}
                >
                    {intl.get('Home.UnderstandBtn')}
                    <IconFont type="icon-youjiantou" className={styles['right-arrow']}></IconFont>
                </Button>
            </div>
        </div>
    );
};
export default PcOneStop;
