import React from 'react';
import intl from 'react-intl-universal';

import Banner from '@/components/common/banner';

import Advantage from './components/Advantage';
import CloudComputing from './components/CloudComputing';
import EasyCloud from './components/EasyCloud';
import Facilities from './components/Facilities';
import OneStop from './components/OneStop';

const Home: React.FC = () => {
    const desc = intl.get('Home.BannerDesc');
    return (
        <>
            <Banner
                type="video"
                title={intl.get('Home.BannerTitle')}
                pcDesc={desc}
                ipadDesc={desc}
                mDesc={intl.get('Home.BannerMDesc')}
                poster="/business/oss-service/finovy-cloud-website/img/home/pc/banner-poster.jpg"
                videoSrc="/business/oss-service/finovy-cloud-website/img/home/pc/banner-v-new.mp4"
                mSrc="/business/oss-service/finovy-cloud-website/img/home/m/banner-new.jpg"
                ipadSrc="/business/oss-service/finovy-cloud-website/img/home/ipad/banner.jpg"
                isHideBtn
            />
            {/* 携手全球领先的云计算提供商为您服务 */}
            <CloudComputing title={intl.get('Home.CloudComputingTitle')} desc={intl.get('Home.CloudComputingDesc')} />
            {/* 一站式IDC与云计算服务解决方案 */}
            <OneStop
                title={intl.get('Home.OneStopTitle')}
                mTitle={intl.get('Home.OneStopMTitle')}
                desc={intl.get('Home.OneStopDesc')}
            />
            {/* 我们的服务优势 */}
            <Advantage title={intl.get('Home.AdvantageTitle')} desc={intl.get('Home.AdvantageDesc')}></Advantage>
            <Facilities title={intl.get('Home.FacilitiesTitle')} desc={intl.get('Home.FacilitiesDesc')}></Facilities>
            {/* 轻松上云，即刻开启 */}
            <EasyCloud></EasyCloud>
        </>
    );
};

export default Home;
