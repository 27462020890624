import type { ReactNode } from 'react';
import React from 'react';

import TransferCom from '@/components/common/transferCom';

import IpadCloudComputing from './ipad';
import MCloudComputing from './m';
import PcCloudComputing from './pc';

interface EasyCloud {
    title?: ReactNode;
    desc?: ReactNode;
}
const CloudComputing: React.FC<EasyCloud> = (props) => {
    return (
        <TransferCom
            m={<MCloudComputing {...props} />}
            ipad={<IpadCloudComputing {...props} />}
            pc={<PcCloudComputing {...props} />}
        />
    );
};
export default CloudComputing;
