import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';

import Title from '@/components/common/title';

import styles from './styles.module.less';
import { getCookie } from '@/utils/utils';

interface Iprops {
    title?: ReactNode;
    desc?: ReactNode;
}
const PcFacilities: React.FC<Iprops> = (props) => {
    const { title, desc } = props;
    const [langType, setLangType] = useState('en-US');
    useEffect(() => {
        const lang = getCookie('lang');
        if (lang) {
            setLangType(lang);
        }
    }, []);
    return (
        <div className={styles.container}>
            <div className={styles['map-con']}>
                <Title title={title} desc={desc} />
            </div>
            <div className={styles['map-bg-con']}>
                {langType === 'zh-CN' || langType === 'zh-TW' ? (
                    <img loading="lazy" src="/business/oss-service/finovy-cloud-website/img/home/m/map-cn.png" alt="" />
                ) : (
                    <img loading="lazy" src="/business/oss-service/finovy-cloud-website/img/home/m/map-en.png" alt="" />
                )}

                <div className={styles.mask}></div>
            </div>
        </div>
    );
};
export default PcFacilities;
