export const homeCloudList = [
    {
        id: '01',
        title: 'Home.CloudListTit1',
        desc: 'Home.CloudListDesc1',
        tagList: ['Home.TagListOne1', 'Home.TagListOne2', 'Home.TagListOne3', 'Home.TagListOne4', 'Home.TagListOne5'],
        url: '/products/serverhosting/',
        price: 13,
    },
    {
        id: '02',
        title: 'Home.CloudListTit2',
        desc: 'Home.CloudListDesc2',
        tagList: ['Home.TagListTwo1', 'Home.TagListTwo2', 'Home.TagListTwo3'],
        url: '/products/serverrental/',
        price: 26,
    },
    {
        id: '03',
        title: 'Home.CloudListTit3',
        desc: 'Home.CloudListDesc3',
        tagList: ['Home.TagListThree1', 'Home.TagListThree2'],
        url: '/products/serverprocure/',
        price: 125,
    },
    {
        id: '04',
        title: 'Home.CloudListTit4',
        desc: 'Home.CloudListDesc4',
        tagList: ['Home.TagListFour1', 'Home.TagListFour2'],
        url: '/products/racksrental/',
        price: 40,
    },
];
