import type { ReactNode } from 'react';
import React from 'react';

import TransferCom from '@/components/common/transferCom';

import IpadFacilities from './ipad';
import MFacilities from './m';
import PcFacilities from './pc';

interface Iprops {
    title?: ReactNode;
    desc?: ReactNode;
    mTitle?: ReactNode;
}
const Facilities: React.FC<Iprops> = (props) => {
    return (
        <TransferCom
            m={<MFacilities {...props} />}
            ipad={<IpadFacilities {...props} />}
            pc={<PcFacilities {...props} />}
        />
    );
};
export default Facilities;
