import type { ReactNode } from 'react';
import React from 'react';

import Title from '@/components/common/title';
import intl from 'react-intl-universal';
import styles from './styles.module.less';
import { homeCloudList } from '../contant';
import classnames from 'classnames';
import Link from 'next/link';

interface PcProps {
    title?: ReactNode;
    desc?: ReactNode;
}
const PcCloudComputing: React.FC<PcProps> = (props) => {
    const { title, desc } = props;
    return (
        <div className={styles['cloud-container']}>
            <Title title={title} desc={desc} />
            <div className={classnames(styles.list, styles['list-1'])}>
                {homeCloudList.map((item, index) => {
                    if (index < 3) {
                        return (
                            <div key={item.id} className={styles.item}>
                                <img loading="lazy" src={item.img} alt={intl.get(item.alt || '')} />
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
            </div>
            <div className={classnames(styles.list, styles['list-2'])}>
                {homeCloudList.map((item, index) => {
                    if (index > 2) {
                        return (
                            <Link
                                key={item.id}
                                className={styles.item}
                                href={item.url}
                                aria-label={intl.get(item.aria || '')}
                            >
                                <img loading="lazy" src={item.img} alt={intl.get(item.alt || '')} />
                            </Link>
                        );
                    } else {
                        return null;
                    }
                })}
            </div>
        </div>
    );
};
export default PcCloudComputing;
