import classnames from 'classnames';
import type { ReactNode } from 'react';
import React from 'react';

import Title from '@/components/common/title';

import styles from './styles.module.less';
import { MapDataList } from '../contant';

interface Iprops {
    title?: ReactNode;
    desc?: ReactNode;
}
const PcFacilities: React.FC<Iprops> = (props) => {
    const { title, desc } = props;
    return (
        <div className={styles.container}>
            <div className={styles['map-con']}>
                <Title title={title} desc={desc} />
            </div>
            <div className={styles['map-bg-con']}>
                {MapDataList.map((item) => {
                    return (
                        <div
                            className={styles.item}
                            style={{ marginLeft: item.ipadMarginLeft, top: item.ipadTop }}
                            key={item.id}
                        >
                            <div className={styles['text-box']}>
                                <div className={styles.title}>{item.title}</div>
                                <div className={styles.desc}>{item.desc}</div>
                            </div>
                            <div className={classnames(styles['images-box'], styles.act)}>
                                <img
                                    loading="lazy"
                                    src="/business/oss-service/finovy-cloud-website/img/home/location.svg"
                                    style={{ width: item.ipadWidth }}
                                    alt=""
                                />
                                <div className={styles['flow-mark']}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div className={styles.mask}></div>
            </div>
        </div>
    );
};
export default PcFacilities;
