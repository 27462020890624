import type { ReactNode } from 'react';
import React from 'react';
import intl from 'react-intl-universal';

import Title from '@/components/common/title';

import styles from './styles.module.less';
import { advantageList } from '../contant';
interface Iprops {
    title?: ReactNode;
    desc?: ReactNode;
}
const PcAdvantage: React.FC<Iprops> = (props) => {
    const { title, desc } = props;
    return (
        <div className={styles.container}>
            <Title title={title} desc={desc} />
            <div className={styles.list}>
                {advantageList.map((item) => {
                    return (
                        <div className={styles.item} key={item.id}>
                            <div className={styles['item-img']}>
                                <img loading="lazy" src={item.imgUrl} alt={`${intl.get(item.title)} icon`} />
                                <p>{intl.get(item.title)}</p>
                            </div>
                            <div className={styles.desc}>{intl.get(item.desc)}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default PcAdvantage;
