export const homeCloudList = [
    {
        id: '001',
        img: '/business/oss-service/finovy-cloud-website/img/cloud/microsoft-cloud.png',
        url: '',
        alt: 'Home.MicrosoftCloud',
    },
    {
        id: '002',
        img: '/business/oss-service/finovy-cloud-website/img/cloud/aws-cloud.png',
        url: '',
        alt: 'Home.AWSCloud',
    },
    {
        id: '003',
        img: '/business/oss-service/finovy-cloud-website/img/cloud/goole-cloud.png',
        url: '',
        alt: 'Home.GooleCloud',
    },
    {
        id: '01',
        img: '/business/oss-service/finovy-cloud-website/img/cloud/huawei-cloud-new.png',
        url: '/products/huawei/',
        aria: 'Menu.ProvideChoices',
        alt: 'Menu.HuaweiCloud',
    },

    {
        id: '02',
        img: '/business/oss-service/finovy-cloud-website/img/cloud/baidu-cloud-new.png',
        url: '/products/baidu/',
        aria: 'Menu.NetInfrastructure',
        alt: 'Menu.BaiduCloud',
    },
    {
        id: '03',
        img: '/business/oss-service/finovy-cloud-website/img/cloud/ali-cloud-new.png',
        url: '/products/ali/',
        aria: 'Menu.ReduceITExp',
        alt: 'Menu.AliCloud',
    },
    {
        id: '04',
        img: '/business/oss-service/finovy-cloud-website/img/cloud/tengxun-cloud-new.png',
        url: '/products/tencent/',
        aria: 'Menu.ProvideExclusiveLink',
        alt: 'Menu.TencentCloud',
    },
];
